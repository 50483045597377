<template>
    <div id="app">
        <!-- router views -->
        <transition appear name="fade" mode="out-in">
            <router-view :key="name" />
        </transition>
    </div>
</template>

<script>
import store from '@/js/store';

const Main = () => import('@/views/Main.vue');
const Cms = () => import('@/views/Cms.vue');

export default {
    data: () => ({
        name: '',
    }),
    components: {},
    computed: {
        pages() {
            let pages = this.$store.pages;
            if (pages.length > 0) {
                return pages;
            }
        },
    },
    async created() {
        console.clear();
        console.log('app.vue');

        await this.$store.getPages();
        this.createRoutes();
    },
    watch: {
        $route: async function (to, from) {
            this.name = to.name == '' || to.name == null ? 'home' : to.name;
            // console.log('route change', from.name, '=>', this.name);
            await this.$store.getQuarks(this.name);
        },
    },
    methods: {
        createRoutes() {
            // get current routes to check if route already exist before adding
            let routes = this.$router.getRoutes();

            // always add '/' route
            let element = {
                path: '/',
                name: '',
                component: Main,
                props: { route: '/' },
                meta: { title: 'Queenie' },
            };
            if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

            // add routes from cms
            let response = this.$store.pages;
            for (let i = 0; i < response.length; i++) {
                let path = response[i].path;
                let title = response[i].title;
                let component;

                element = {
                    path: '/' + path,
                    name: path,
                    component: Main,
                    props: { route: path },
                    meta: { title: title },
                };

                if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);
            }

            // always add '/cms' route
            element = {
                path: '/cms',
                name: 'cms',
                component: Cms,
                props: { route: '/cms' },
            };
            if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

            // uncomment the following lines to generate info for sitemap.txt file
            // routes = this.$router.getRoutes();
            // let url = 'https://test.queenie.nl';
            // routes.forEach((element) => {
            //     console.log(url + element.path);
            // });
        },
    },
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
    font-family: Roboto_Slab;
    src: url('assets/fonts/Roboto_Slab/RobotoSlab-Light.ttf');
}

@font-face {
    font-family: Roboto-Regular;
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: Roboto-Light;
    src: url('assets/fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
    font-family: Roboto-Black;
    src: url('assets/fonts/Roboto/Roboto-Black.ttf');
}

html {
  scroll-behavior: smooth;
}

body {
    height: 100vh;
}

#app {
    font-family: 'Roboto-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #002b55;
    overflow-x: hidden;
}

h1 {
    font-family: 'Roboto_Slab';
    font-size: 56px;
    text-align: center;
}

h2 {
    font-family: 'Roboto-Light';
    font-size: 22px;
    line-height: 40px;
    text-align: center;
}

h3 {
    font-family: 'Roboto-Black';
    font-size: 30px;
    color: #ed672b;
}

p {
    font-size: 15px;
    line-height: 26px;
}

a {
    color: inherit;
}

a:hover {
    color: inherit;
    text-decoration: underline;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
